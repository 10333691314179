// MARGINS
.mauto { margin: auto !important; }

.m0 { margin: 0px !important; } 
.m5 { margin: 5px !important; }
.m10 { margin: 10px !important; }
.m15 { margin: 15px !important; }
.m20 { margin: 20px !important; }
.m25 { margin: 25px !important; } 
.m30 { margin: 30px !important; }
.m35 { margin: 35px !important; }
.m40 { margin: 40px !important; }
.m45 { margin: 45px !important; }
.m50 { margin: 50px !important; }

.mt0 { margin-top: 0px !important; } 
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; } 
.mt30 { margin-top: 30px !important; }
.mt35 { margin-top: 35px !important; }
.mt40 { margin-top: 40px !important; }
.mt45 { margin-top: 45px !important; }
.mt50 { margin-top: 50px !important; }
.mt100 { margin-top: 100px !important; }

.mr0 { margin-right: 0px !important; } 
.mr5 { margin-right: 5px !important; }
.mr10 { margin-right: 10px !important; }
.mr15 { margin-right: 15px !important; }
.mr20 { margin-right: 20px !important; }
.mr25 { margin-right: 25px !important; } 
.mr30 { margin-right: 30px !important; }
.mr35 { margin-right: 35px !important; }
.mr40 { margin-right: 40px !important; }
.mr45 { margin-right: 45px !important; }
.mr50 { margin-right: 50px !important; }

.mb0 { margin-bottom: 0px !important; } 
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; } 
.mb30 { margin-bottom: 30px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb45 { margin-bottom: 45px !important; }
.mb50 { margin-bottom: 50px !important; }

.ml0 { margin-left: 0px !important; } 
.ml5 { margin-left: 5px !important; }
.ml10 { margin-left: 10px !important; }
.ml15 { margin-left: 15px !important; }
.ml20 { margin-left: 20px !important; }
.ml25 { margin-left: 25px !important; } 
.ml30 { margin-left: 30px !important; }
.ml35 { margin-left: 35px !important; }
.ml40 { margin-left: 40px !important; }
.ml45 { margin-left: 45px !important; }
.ml50 { margin-left: 50px !important; }

// PADDINGS
.p0 { padding: 0px !important; } 
.p5 { padding: 5px !important; }
.p10 { padding: 10px !important; }
.p15 { padding: 15px !important; }
.p20 { padding: 20px !important; }
.p25 { padding: 25px !important; } 
.p30 { padding: 30px !important; }
.p35 { padding: 35px !important; }
.p40 { padding: 40px !important; }
.p45 { padding: 45px !important; }
.p50 { padding: 50px !important; }

.pt0 { padding-top: 0px !important; } 
.pt5 { padding-top: 5px !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; } 
.pt30 { padding-top: 30px !important; }
.pt35 { padding-top: 35px !important; }
.pt40 { padding-top: 40px !important; }
.pt45 { padding-top: 45px !important; }
.pt50 { padding-top: 50px !important; }

.pr0 { padding-right: 0px !important; } 
.pr5 { padding-right: 5px !important; }
.pr10 { padding-right: 10px !important; }
.pr15 { padding-right: 15px !important; }
.pr20 { padding-right: 20px !important; }
.pr25 { padding-right: 25px !important; } 
.pr30 { padding-right: 30px !important; }
.pr35 { padding-right: 35px !important; }
.pr40 { padding-right: 40px !important; }
.pr45 { padding-right: 45px !important; }
.pr50 { padding-right: 50px !important; }

.pb0 { padding-bottom: 0px !important; } 
.pb5 { padding-bottom: 5px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; } 
.pb30 { padding-bottom: 30px !important; }
.pb35 { padding-bottom: 35px !important; }
.pb40 { padding-bottom: 40px !important; }
.pb45 { padding-bottom: 45px !important; }
.pb50 { padding-bottom: 50px !important; }

.pl0 { padding-left: 0px !important; } 
.pl5 { padding-left: 5px !important; }
.pl10 { padding-left: 10px !important; }
.pl15 { padding-left: 15px !important; }
.pl20 { padding-left: 20px !important; }
.pl25 { padding-left: 25px !important; } 
.pl30 { padding-left: 30px !important; }
.pl35 { padding-left: 35px !important; }
.pl40 { padding-left: 40px !important; }
.pl45 { padding-left: 45px !important; }
.pl50 { padding-left: 50px !important; }


// WIDTH
.width0p { width: 0% !important; }
.width5p { width: 5% !important; }
.width10p { width: 10% !important; }
.width15p { width: 15% !important; }
.width20p { width: 20% !important; }
.width25p { width: 25% !important; }
.width30p { width: 30% !important; }
.width35p { width: 35% !important; }
.width40p { width: 40% !important; }
.width45p { width: 45% !important; }
.width50p { width: 50% !important; }
.width55p { width: 55% !important; }
.width60p { width: 60% !important; }
.width65p { width: 65% !important; }
.width70p { width: 70% !important; }
.width75p { width: 75% !important; }
.width80p { width: 80% !important; }
.width85p { width: 85% !important; }
.width90p { width: 90% !important; }
.width95p { width: 95% !important; }
.width100p { width: 100% !important; }


// MIN WIDTH
.minwidth0p { min-width: 0% !important; }
.minwidth5p { min-width: 5% !important; }
.minwidth10p { min-width: 10% !important; }
.minwidth15p { min-width: 15% !important; }
.minwidth20p { min-width: 20% !important; }
.minwidth25p { min-width: 25% !important; }
.minwidth30p { min-width: 30% !important; }
.minwidth35p { min-width: 35% !important; }
.minwidth40p { min-width: 40% !important; }
.minwidth45p { min-width: 45% !important; }
.minwidth50p { min-width: 50% !important; }
.minwidth55p { min-width: 55% !important; }
.minwidth60p { min-width: 60% !important; }
.minwidth65p { min-width: 65% !important; }
.minwidth70p { min-width: 70% !important; }
.minwidth75p { min-width: 75% !important; }
.minwidth80p { min-width: 80% !important; }
.minwidth85p { min-width: 85% !important; }
.minwidth90p { min-width: 90% !important; }
.minwidth95p { min-width: 95% !important; }
.minwidth100p { min-width: 100% !important; }


// Spacer
.spacer10 { width: 100%; height: 10px; }
.spacer20 { width: 100%; height: 20px; }
.spacer30 { width: 100%; height: 30px; }
.spacer40 { width: 100%; height: 40px; }
.spacer50 { width: 100%; height: 50px; }
.spacer60 { width: 100%; height: 60px; }
.spacer70 { width: 100%; height: 70px; }
.spacer80 { width: 100%; height: 80px; }
.spacer90 { width: 100%; height: 90px; }
.spacer100 { width: 100%; height: 100px; }
.spacer150 { width: 100%; height: 150px; }
.spacer200 { width: 100%; height: 200px; }
