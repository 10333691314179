// ---------------------- NAVBAR ----------------------
.navbar {
	z-index: 1000;

	border: none;
	/* pointer-events: none; */
	background-color: $nav_container_bgcolor;
	width: $nav_container_width;
	// height: $nav_container_height;

	// EXCEPTIONNEL :
	top: 90px;
	left: 40px;
	transition: top 0.3s;

	&.scrolled {
		top: 40px;
	}
} 

.navbar-header {
	float: none;
	position: relative;
	top: 0;
	transition: top 0.3s;

	.tels {
		padding: 20px;
		padding-top: 10px;

		.label {
			display: block;
			font-size: 1.4em;
			font-weight: 300;
			line-height: 1;
			color: $color_white;
			margin: 0;
			padding: 0;
			text-align: left;
			padding-left: 0;

			.highlight {
				color: $color4;
			}
		}

		a {
			display: block;
			font-size: 1.4em;
			font-weight: 700;
			color: $color_white;
			margin: 0;
			margin-bottom: 10px;
			text-align: left;
			padding-left: 0;
			text-decoration: none;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.navbar-toggle {
	z-index: 1;
	display: block;
	border: none;
	margin-top: $nav_toggle_decalage; // Corrections bug pixel...
	margin-right: $nav_toggle_decalage;
	height: $nav_toggle_size;
	width: $nav_toggle_size * 1.2;
	pointer-events: all;
}

.navbar-toggle .icon-bar {
	background-color: $nav_toggle_color !important;
}
.navbar-collapse.collapse {
	display: none !important;
}
.navbar-nav {
	float: none !important;
}
.navbar-nav>li {
	float: none;
}
.navbar-nav>li>a {
	font-size: $nav_links_fontsize;
	font-weight: 500;
	color: $color_nav_links !important;
	text-decoration: none;
	padding: 10px;
	pointer-events: all;
	line-height: $nav_links_lineheight;
}
.navbar-nav>li>a:hover, .navbar-nav>li>a:active {
	background-color: transparent !important;
	color: $color_nav_links_hover !important;
	text-decoration: $color_nav_links_decoration;
}

.sousmenu { padding-left: $nav_links_sousmenu_decalage; }

.navtitle {
	font-size: $nav_links_fontsize;
	font-weight: 500;
	color: $nav_title_color !important;
	text-decoration: none;
	padding-left: 10px;
	margin-top: $nav_title_color_margintop;
	margin-bottom: $nav_title_color_marginbottom;
}

.navbar-collapse {
	pointer-events: all;
	z-index: 100;
}
.navbar-collapse.collapse.in{
	position: absolute;
	display: block !important;
	top: 0;
	right: 0;
	width: $nav_width;
}
.navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus, .navbar .navbar-nav > li > a:active, .navbar .navbar-nav > li > a:visited {
    background-color: transparent !important;
}


#navbar ul a.active {
	color: $activelink_color !important;
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
	position: absolute;
	z-index: 100;
	max-height: 100vh;
	top: 0;
	right: 0;
	width: $nav_width;
}

#navbar {
	height: 100% !important;
	overflow: hidden;
	background-color: $color_black;
	text-align: left;
	transition: width 0.3s;

	// EXCEPTIONNEL :
	transform: translateX(99%);
}

// Intérieur de la collapse
.nav { // Container navlinks
	display: inline-block;
	float: $nav_links_side !important;
	margin: 0;
	margin-top: $nav_links_margintop;
	margin-left: $nav_links_marginleft;
	margin-right: $nav_links_marginright;
	max-width: 80%;
	text-align: left;
	min-width: 200px;
	white-space: nowrap; // Eviter retours ligne à l'apparition horizontale
}

.filler {
	position: absolute;
	width: $nav_width;
	top:0; right:0; bottom:0; left:0;
}

// Barres toggle
.navbar-toggle .icon-bar {
	width: 100%;
	margin-bottom: 6px !important;
	height: 4px;
}

.top-bar {
	margin-top: 0px;
}

// Animation du toggler
.navbar-toggle {
	color: $nav_toggle_color;
	.icon-bar {
		transition: all 0.2s;
		margin-left: 4px;
	}
	.top-bar {
		transform: rotate(45deg);
		transform-origin: 0% 50%;
	}
	.middle-bar {
		opacity: 0;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 0% 50%;
		width: 100%;
	}
}

.navbar-toggle.collapsed {
	.icon-bar {
		margin-left: 0px;
	}
	.top-bar {
		transform: rotate(0);
	}
	.middle-bar {
		opacity: 1;
	}
	.bottom-bar {
		transform: rotate(0);
		width: 50%;
	}
}
// ---------------------- FIN NAVBAR ----------------------

.mot_menu {
	position: absolute;
	font-size: $nav_mot_fontsize;
	font-weight: $nav_mot_fontweight;
	color: $nav_mot_color;
	top: 0;
	left: 50%;
	transform: translate(-50%, -$nav_mot_decalage);
	color: $color_white;
	opacity: 0.9;
}

.mot_menu.black {
	color: $color_black;
}

.navbar-toggle.collapsed.black .icon-bar {
	background-color: $color_black !important;
}

.navbar-toggle.black {
	color: $color_black;
}

// Ajouts ponctuels

#navlink_tarifs, #navlink_tarifs:hover, #navlink_tarifs:active {
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 14px;
	color: $color_black !important;
	background-color: $color_white !important;
	line-height: 1em !important;
}

#navlink_tarifs:hover, #navlink_tarifs:active {
	color: $color1 !important;
}

#navlink_tarifs i {
	position: absolute;
	left: 0;
	font-size: 1.5em;
	transform: translateX(-140%);
}

#nav_textes_basgauche {
	position: absolute;
	left: 30px;
	bottom: 10px;
}

#nav_textes_basgauche div {
	font-family: "korolev-compressed",sans-serif !important;
	text-transform: uppercase;
	font-size: 2em;
	font-weight: 500;
	letter-spacing: 0.1em;
	line-height: 1.3em;
	margin: 0;
}


// RESPONSIVE

@media (max-width: $screen-md) { 
	.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse, .navbar-collapse.collapse.in { 
		width: 100%;
	}

	.navbar {
		pointer-events: none;
		background-color: transparent;
		width: 100%;
		height: $nav_container_height;
		top: 0;
		left: 0px;

		.navbar-header {
			top: 80px;
		}

		&.scrolled {
			top: 0;

			.navbar-header {
				top: 0;
			}
		}

		.close-nav {
			cursor: pointer;
			position: absolute;
			right: 15px;
			top: 8px;
			color: $color_white;
			font-size: 2.5em;
			line-height: 1;
			opacity: 1;
		}
	}

	#navbar {
		height: 100vh !important;
		transform: none;
	}

	#navbar .nav {
		float: left !important;
	}

	.nav {
		margin-top: 50vh !important;
		transform: translateY(-50%);
		margin-left: 40vw !important;

		.tels {
			margin: 30px 0;

			* {
				font-size: 2rem;
				font-weight: 300;
				line-height: 1;
			}

			.label {
				padding-bottom: 0;
				margin-bottom: 5px;
				// margin: 0;

				.highlight {
					color: $color4;
				}
			}

			.tel {
				font-weight: 700;
				padding-top: 0;
				margin-bottom: 15px;
			}
		}
	}

	.nav i {
		font-size: 2em; 
	}

	.navbar-nav > li > a {
		font-size: 2em;
		line-height: 0.75em;
		color: $color_white !important;
	}

	.navbar-toggle {
		pointer-events: all;
		background-color: $color_black;
	}

	.filler {
		width: 100vw;
	}
}


// DETAILS A NE PAS REPRENDRE
#logo_nav {
	width: 80%;
	margin-left: 10%;
	margin-top: calc(#{$nav_toggle_decalage} + #{$nav_toggle_size} + 10px);
	margin-bottom: 10px;
	height: calc(#{$nav_container_height} - #{$nav_toggle_decalage} - #{$nav_toggle_size} - 90px);
	background: url(../images/logo_nav.png) no-repeat center center;
	background-size: contain;
}

#logo_nav_mobile {
	position: absolute;
	width: 30vw;
	height: 50vh;
	left: 0;
	top: 20px;
	background: url(../images/logo_nav.png) no-repeat left top;
	background-size: contain;
}

.navbar-header p {
	text-align: center;
	margin: 0;
	color: $color_white;
}

.nav { // Container navlinks
	display: inline-block;
	float: $nav_links_side !important;
	margin: 0;
	margin-top: $nav_container_height / 2;
	margin-left: $nav_links_marginleft;
	margin-right: $nav_links_marginright;
	max-width: 80%;
	text-align: left;
	min-width: 200px;
	white-space: nowrap; // Eviter retours ligne à l'apparition horizontale
	transform: translateY(-50%);
}

#nav_fb i {
	color: $color_white !important;
	font-size: 2.5rem;
}