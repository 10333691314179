html { 
	overflow-x: hidden;
	overflow-y: scroll; // IMPORTANT : ignorer la scrollbar dans la largeur de la page
	width: 100%;
}

body {
	background-color: $color1;
	margin: 0; padding: 0;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden; 
	width: 100%;
	padding-right: 0 !important;
}

.glyphicon {
	font-family: Glyphicons Halflings !important;
}

.grecaptcha-badge {
    display: none !important;
}

h1 {
	color: $color_black;
	letter-spacing: 0.08em;
	font-size: calc(1.5em + 2vw);
}

h2 {
	color: $color_black;
	letter-spacing: 0.2em;
	font-size: calc(1em + 2vw);
}

p {
	color: $color_black;
	font-size: $p_font-size;
	font-weight: $p_font-weight;
	line-height: $p_line-height;
	margin: 0;
}

p.closeline {
	line-height: 1em;
}

@media (max-width: $screen-md) {
	p {
		font-size: $p_font-size_responsive;
		line-height: $p_line-height_responsive;
	}
}

a {
	color: $color3;
}

a:hover, a:focus, a:active {
	color: darken($color3, 10%);
	text-decoration: none;
}

.filler {
	position: absolute;
	z-index: -10;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
}

.filet {
	width: 100%;
	height: 1px;
	background-color: $color_black;
}

.big_title {
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 40px;
	border-bottom: 1px solid $color_black;
	font-weight: 700;
}

.plus_button {
	cursor: pointer;
	position: relative;
	display: inline-block;
	background-color: $color2;
	font-size: 2em;
	font-weight: 700;
	line-height: 0.5em;
}

.plus_button.centered {
	margin-left: 50%;
	margin-top: 50%;
	transform: translate(-50%, -100%);
}

.plus_button .plus {
	z-index: 1;
	position: relative;
	display: inline-block;
	color: white;
	font-size: 1.3em;
	font-weight: 700;
	line-height: 0.5em;
}

.plus_button i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	display: inline-block;
	color: $color2;
	font-size: 200%;
	font-weight: 300;
}

.plus_button .fa-long-arrow-right {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-230%, -50%);
	color: $color2;
	font-size: 100%;
	font-weight: 300;
}

.plus_button_nom {
	position: absolute;
	top: 220%;
	left: 50%;
	transform: translate(-50%, 0%);
	display: inline-block;
	color: $color2;
	font-size: 0.5em;
	font-weight: 300;
}

.fleche_fine {
	display: inline-block;
	width: $fleche_fine_width;
	height: $fleche_fine_width * 0.58;
	margin-left: 20px;
}

.fleche_noire { background: url(../images/fleche_noire.png) no-repeat center center; background-size: contain; }
.fleche_blanche { background: url(../images/fleche_blanche.png) no-repeat center center; background-size: contain; }

.fleche_fine.haut {
	transform: rotate(-90deg);
}

.puce_oie {
	display: inline-block;
	height: 20px;
	width: 20px * 0.689;
	margin-right: 4px;
	background: url(../images/puce_oie.png) no-repeat center center;
	background-size: contain;
}

#lightbox_video {
	padding: 0 !important;
}




// PAGE

.modal_galerie_haut {
	.modal-content {
		background-color: $color_almostblack;
	}

	.item {
		background-color: $color_almostblack;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			max-height: 80vh;
		}
	}
}

.marquee-wrapper {
    color: $color_white;
    background-color: $color_black;
    font-weight: 700;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px 0;

    .inner {
        display: flex;
        white-space: nowrap;

		.texte {
			font-size: 2rem;
			font-weight: 700;
			color: $color_white;
		}
    }

    .separator {
        display: flex;
        align-items: center;
    }
    .separator:after {
        content: "";
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        margin: 0 20px;
		border-radius: 1000px;
        background-color: $color_white;
        vertical-align: middle;
    }
}

#fond_entete {
	position: absolute;
	z-index: -2;
	width: 100vw;
	height: $fond_entete_height;
	background: url(../images/fond_haut.jpg) no-repeat center bottom;
	background-size: cover;
}

#fond_triangle {
	position: absolute;
	z-index: -1;
	right: 0;
	top: 0;
	width: $fond_triangle_width;
	height: $fond_triangle_width * 2.009;
	background: url(../images/fond_triangle.png) no-repeat center center;
	background-size: cover;
}

#logo_triangle {
	position: absolute;
	width: $logo_triangle_width;
	height: $logo_triangle_width * 0.903;
	top: 50%;
	left: $logo_triangle_decalage;
	transform: translateY(-50%);
	background: url(../images/logo_noir.png) no-repeat center center;
	background-size: cover;
}

$element_fond_1_height: $element_fond_1_width * 1.54;

#element_fond_1 {
	// position: absolute;
	// z-index: -2;
	// top: calc(#{$fond_entete_height} - #{$element_fond_1_height});
	// left: 15vw;
	width: $element_fond_1_width;
	height: $element_fond_1_width * 1.54;
	background: url(../images/fond_element_1.png) no-repeat center bottom;
	background-size: contain;
}

#contenu_page {
	z-index: 1;
	padding: 40px;
	padding-top: 35vh;
	padding-right: 60px;
}

.slider_container {
	margin-bottom: 40px;

	.img-responsive {
		cursor: pointer;
		border: none;
		box-shadow: 0 2px 6px rgba(0,0,0,0.1);
		aspect-ratio: 1/1;
		width: 100%;
		object-fit: cover;
		border-radius: 6px;
	}

	.row {
		margin-left: -10px;
		margin-right: -10px;
	}

	.col-xs-6, .col-sm-4, .col-md-3 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.carousel-control {
		background: none;
		width: 5%;
		opacity: 1;

		.bloc {
			position: absolute;
			top: 50%;
			height: 40px;
			width: 40px;
			background-color: $color_black;
			border-radius: 8px;

			.arrow {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30px;
			}

			&.left {
				left: 0;
				transform: translate(-50%, -50%) rotate(45deg);

				.arrow {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
			&.right {
				right: 0;
				transform: translate(50%, -50%) rotate(-45deg);

				.arrow {
					transform: translate(-50%, -50%) rotate(-135deg);
				}
			}
		}

		&:hover {
			.bloc {
				background-color: lighten($color_black, 15%);
			}
		}
	}
} 


#bloc_intro_container {
	width: 100%;
	margin-bottom: 40px;
}

#photo_lien_galerie_container {
	padding-top: 30px;
	padding-right: 30px;
	text-align: center;
	margin-bottom: 60px;
}

#photo_lien_galerie_container .plus_button {
	margin-top: 50px;
}

#photo_lien_galerie {
	cursor: pointer;
	width: 100%;
	height: 16vw;
	background: url(../images/img_contenu_1.jpg) no-repeat center center;
	background-size: cover;
}

#lien_galerie {
	padding-left: 80px;
}

.paragraphe_intro {
	padding: 20px;
	font-size: calc(0.8em + 0.7vw);
	font-weight: 500;
	line-height: 1.5;

	strong {
		font-size: 2rem;
	}
}

@media (min-width: $screen-lg) {
	.paragraphe_intro {
		font-size: calc(0.6em + 0.6vw);
	}
}


// Cartes
.carte_nav_container {
	display:block;
	// text-align:justify;
	margin-top: 10px;
	margin-bottom: 30px;
}

.carte_nav_container:after {
	display:inline-block;
	width:95%; /* 100% peut-être excessif */
	content:'';
}

.carte_nav_bouton {
	display:inline-block;
	cursor: pointer;
	font-size: 1.2em;
	font-weight: 700;
	line-height:1.2em;
	color: $color_black;
	// background-color: $color_beige;
	padding: 15px 0;
	margin-right: 80px;
	border-bottom: 0 solid $color_beige;
	transition: border-bottom 0.1s;

	&:hover {
		border-bottom: 4px solid $color_beige;
	}
}

.carte_nav_bouton.active {
	// background-color: $color_black;
	border-bottom: 4px solid $color_beige;
}

.carte_nav_bouton .fleche_fine {
	display: none;
}

.carte_table, .carte_table_salon {
	display: none;
	width: 100%;
	font-size: calc(0.8em + 0.7vw);
	text-transform: uppercase;
}

@media (min-width: $screen-lg) {
	.carte_table, .carte_table_salon {
		font-size: calc(0.6em + 0.6vw);
	}
}

.carte_table td, .carte_table_salon td {
	padding-bottom: 10px;
}

.carte_table .description, .carte_table_salon .description {
	font-size: 1em;
	font-weight: 500;
	text-transform: lowercase;
}

.carte_table.display, .carte_table_salon.display { display: table; }

.carte_table .prix, .carte_table_salon .prix {
	text-align: right;
	vertical-align: top;
	padding-left: 10px;
	padding-top: 4px;
}

.carte_table .puce_oie, .carte_table_salon .puce_oie {
	transform: translateY(4px);
}
// FIN Cartes


.horaires_container {
	// display: flex;
	// align-items: stretch;

	#element_fond_1 {

	}

	#horaires_ouverture {
		height: 100%;
		min-height: $element_fond_1_height;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.bloc {
			margin-right: 30px;
			color: $color_white;
			background-color: $color_almostblack;
			padding: 30px;
			border-radius: 14px;
			font-size: 1.8rem;
			
			* {
				color: $color_white;
			}

			h4 {
				margin-top: 0;
			}
		}
	}
}

.tableau_horaires {
	width: 100%;
}

.tableau_horaires td {
	font-size: 1.2em;
	font-weight: 500;
}

.citation {
	position: relative;
	display: inline-block;
	font-size: 1.7em;
	padding-left: 20px;
	border-left: 1px solid $color_black;
}

.citation:before {
	position: absolute;
	content: '“';
	top: -30px;
	left: 10px;
	font-size: 1.3em;
}

.citation:after {
	position: absolute;
	content: '”';
	bottom: -40px;
	right: 0px;
	font-size: 1.3em;
}

.vignette_container {
	position: relative;
	width: 100%;
	padding-bottom: 100%;
}

.vignette {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

#vignette_1 { background: url(../images/fond_bas.jpg) no-repeat center center; background-size: cover; }
#vignette_2 { background: url(../images/img_contenu_2.jpg) no-repeat center center; background-size: cover; }
#vignette_3 { background: url(../images/img_contenu_3.jpg) no-repeat center center; background-size: cover; }

#fond_bas {
	z-index: 0;
	height: $fond_bas_height;
	background: url(../images/fond_bas.jpg) no-repeat center top;
	background-size: cover;
	margin-top: -25vw;
}


#carte_googlemaps {
	height: 80vh;
}

.bloc_map {
	position: absolute;
	z-index: 1;
	padding: 40px;
	margin-top: 40px;
}

#bloc_map_haut {
	background-color: $color_black;
	// top: 0;
	bottom: 40%;
	// transform: translateY(-100%);

	.date {
		font-size: 1.6em;
		margin-bottom: 5px;
	}

	.titre {
		font-weight: 300;
		margin-bottom: 10px;
	}

	.texte {
		a {
			color: $color_white;
		}
	}
}

#bloc_map_haut p { color: $color1 !important; }

#bloc_map_bas {
	background-color: $color1;
	top: 60%;
	margin-top: 0;
	// transform: translateY(0);
}

#bloc_map_bas p { color: $color_black !important; }

.bloc_map p {
	font-weight: 500;
	line-height: 1.1em;
}

.bloc_map .titre {
	font-size: 1.6em;
	font-weight: 600;
	margin-bottom: 20px;
}

.bloc_map a {
	font-size: 1.3em;
	font-weight: 600;
	margin-top: 20px;
}

.modal-dialog {
	top: 50vh;
	transform: translateY(-50%) !important;
}

.croix_fermeture {
	cursor: pointer;
	position: absolute;
	z-index: 1000000;
	color: white;
	font-size: 2em;
	right: 10px;
	top: 6px;
}

#logo_nav_page {
	height: $hauteur_logo_responsive;
	width: $hauteur_logo_responsive * 0.493;
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 40px;
	background: url(../images/logo_nav.png) no-repeat center center;
	background-size: contain;
}

// RESPONSIVE
@media (max-width: $screen-lg) {
	#photo_lien_galerie {
		height: 24vw;
	}
}


@media (max-width: $screen-md) {
	#photo_lien_galerie {
		height: 40vw;
	}

	#fond_entete {
		height: $fond_entete_height / 2;
	}

	#element_fond_1_mobile {
		position: absolute;
		z-index: -20;
		top: -50vw;
		right: -25vw;
		width: 50vw;
		height: 50vw;
		background: url(../images/fond_element_1.png) no-repeat center bottom;
		background-size: contain;
	}

	#contenu_page {
		float: none !important;
		padding: 30px;
		padding-top: 40px;
	}

	#bloc_intro_container {
		width: 100%;
		transform: translateX(0%);
	}

	#photo_lien_galerie_container {
		padding-top: 0px;
		padding-right: 0px;
	}

	.paragraphe_intro {
		margin-top: 30px;
		padding: 0px;
	}

	.big_title {
		text-align: left;
		margin-top: 50px;
		padding-bottom: 10px;
		border: none;
	}

	// .carte_table, .carte_table_salon {
	// 	font-size: calc(0.6em + 0.6vw);
	// }

	.carte_nav_container {
		text-align: left;
		margin-bottom: 30px;
	}

	.carte_nav_bouton {
		display: block;
		font-weight: 500;
		margin-top: 20px;
		// color: $color3;
	}

	.carte_nav_bouton .fleche_fine {
		display: inline-block;
		margin-left: 0;
		margin-right: 10px;
		background: transparent;
		filter: invert(100%);
	}

	.carte_nav_bouton.active .fleche_noire {
		background: url(../images/fleche_noire.png) no-repeat center center; 
		background-size: contain;
	}

	#horaires_ouverture {
		float: none !important;
	}

	#fond_bas {
		z-index: 0;
		height: auto;
		background: none;
		margin-top: 50px;
		margin-bottom: 50px;
	}

	#fond_bas .pull-right {
		float: none !important;
		text-align: center;
	}

	.citation {
		margin-top: 40px;
	}

	#fond_bas .plus_button {
		margin: auto;
		margin-top: 30px;
	}

	#fond_bas div {
		float: none !important; 
	}

	.bloc_map {
		position: relative;
		z-index: 1;
		padding: 40px;
		margin: 0px;
		width: 90vw;
		margin-left: 5vw;
	}

	#bloc_map_haut {
		transform: translateY(0%);
	}

	#bloc_map_bas {
		transform: translateY(0%);
		background-color: white;
		// margin-bottom: 30px;
	}

	.plus_button .fa-long-arrow-right {
		display: none;
	}

	#lien_galerie {
		padding: 0;
	}

}


@media (max-width: $screen-sm) {

}