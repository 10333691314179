
footer {
	background-color: $color_black;
	padding: $footer_padding !important;
	color: $color_white;
}

#container_form_footer {
	max-width: 800px;
	margin-bottom: 60px;
}

.phrase_form {
	margin-top: 30px;
	margin-bottom: 30px;
	color: $color_white;
}

.phrase_form a {
	color: $color4;
	text-decoration: underline;
}

.form-control, .form-control:hover, .form-control:active, .form-control:focus {
	border: none;
	border: none;
	box-shadow: none;
	background-color: #eeeeee;
}

.form-group {
	padding: 6px;
}

input, textarea {
	background-color: $color_black !important;
	color: $color_white !important;
	border: none;
	box-shadow: none;
	border-bottom: 1px solid $color_white !important;
}

form button {
    background-color: transparent;
    color: $color_white;
    font-size: 1.5em !important;
    font-weight: 700 !important;
    padding: 0 !important;
}

form button:hover, form button:active, form button:focus {
    color: darken($color_white, 20%) !important;
}

.confirmation_envoi_mail {
	display: none;
	padding: 20px;
	background-color: $color1;
	text-align: center;
	color: $color_black;
	margin-bottom: 10px;
}


#bloc_adresse {
	display: flex;
	font-size: 1.3em;
	font-weight: 300;
	line-height: 1;
	
	.bloc {
		padding-right: 30px;
		margin-right: 30px;
		border-right: 1px solid $color_white;

		&:last-child {
			padding-right: 0;
			margin-right: 0;
			border-right: none;
		}
	}

	> * {
		display: block;
	}

	.tel {
		display: block;
		color: $color_white;
		font-weight: 700;
		margin: 10px 0;
	}
}

#socials {
	
	a {
		color: $color_white !important;
		font-size: 2em;
	}
}


#legal-mentions {
	display: none;
}

.legals_container {
	cursor: pointer;
	position: absolute;
	bottom: $footer_padding;
	right: $footer_padding;
}

.legals_container span {
	color: $color_white;
	font-weight: 700;
}

.popover {
	min-width: 40vw;
	max-width: 80vw;
}


@media (max-width: $screen-md) {
	.popover {
		min-width: 80vw;
		max-width: 90vw;
	}

	#bloc_adresse {
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		// text-align: center;

		.bloc {
			padding: 0;
			margin: 0;
			border: none;
			margin-bottom: 40px;
		}
	}
}