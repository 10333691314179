
// ------------------------------
// ||   Variables générales    ||
// ------------------------------

$color_black: #000000;
$color_almostblack: #1d1d1b;
$color_white: #ffffff;
$color1: #f5f4ed;
$color2: lighten($color_black, 10%);
$color3: lighten($color_black, 35%);
$color4: #F8B133;

$color_beige: #b2ae9b;

$p_font-weight: 300;
$p_font-size: calc(0.7em + 0.5vw);
$p_line-height: calc(1.4em + 0.5vw);
$p_font-size_responsive: calc(0.9em + 0.7vw);
$p_line-height_responsive: calc(1.5em + 0.6vw);

$modal_height: 80vh;
$fleche_fine_width: 30px;

// --------- Nav ---------

// Container
$nav_container_bgcolor: $color_black;
$nav_container_width: 240px;
$nav_container_height: 30vw;

// Largeur du menu déplié
$nav_width: 20vw;

// Toggle
$nav_toggle_decalage: 10px;
$nav_toggle_size: 40px;
$nav_toggle_color: $color_white;

// Couleurs liens
$color_nav_links: #aaaaaa;
$color_nav_links_hover: $color_white;
$color_nav_links_decoration: none;
$activelink_color: $color_white;

// Position liens
$nav_links_side: right;
$nav_links_margintop: 8vh;
$nav_links_marginleft: 0;
$nav_links_marginright: 0;
$nav_links_fontsize: 1.6em;
$nav_links_lineheight: 7px;

$nav_title_color: $color1; 
$nav_title_color_margintop: 20px;
$nav_title_color_marginbottom: 0px;
$nav_links_sousmenu_decalage: 30px;

// Mot MENU
$nav_mot_fontsize: 0.9em;
$nav_mot_fontweight: 700;
$nav_mot_color: darken($color_white, 5%);
$nav_mot_decalage: 14px;



// ------------------------------
// ||   Variables Bootstrap    ||
// ------------------------------

$grid-float-breakpoint: 999999999px;
$input-border: none !important;
$input-border-radius: 0px !important;
$input-border-focus: $color1;
$grid-gutter-width: 0;
$link-color: #cccccc;
$link-hover-color: darken($link-color, 15%);



// ------------------------------
// ||      Page : Accueil      ||
// ------------------------------

// En-tête
$hauteur_logo_responsive: 40vh;
$fond_entete_height: 140vh;
$fond_triangle_width: 75vw;
$logo_triangle_width: 10vw;
$logo_triangle_decalage: 3vw;
$element_fond_1_width: 20vw;


$fond_bas_height: 140vh;



// -----------------------------
// ||      Page : Footer      ||
// -----------------------------

$footer_padding: 30px;